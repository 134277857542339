import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import Questionnaire from '../components/organisms/questionnaire'
import KeyFeatures from '../components/molecules/key-features'

import steps from "../data/pages/questionnaire/steps.json"
import verifyInformation from "../data/pages/questionnaire/verify.json"
import content from "../data/pages/questionnaire/content.json"

const QuestionnairePage = () => (
  <Layout showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <br />
    <br />
    <br />
    <Heading 
        title='A Partnership That Builds Your Wealth Virtually&nbsp;Risk-Free'
        subTitle=''
        weight='light'
        color='dark'
    />
    <Questionnaire 
      steps={steps}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      // formType="wall-bed"
    />
    <KeyFeatures
      features={[
        {
            title: 'We build',
            image: 'icon-1.png',
            imageAlt: ''
        },
        {
            title: 'We market',
            image: 'icon-2.png',
            imageAlt: ''
        },
        {
            title: 'We sell',
            image: 'icon-3.png',
            imageAlt: ''
        }
    ]}
    />
  </Layout>
)

export default QuestionnairePage
